import HelpRoot from "@/views/help/HelpRoot";
import Bridge from "@/views/help/Bridge";

export default [
    {
        path: "/help",
        component: HelpRoot,
        redirect: { name: "help-bridge" },
        children: [
            {
                path: "bridge",
                name: "help-bridge",
                component: Bridge,
            },
        ],
    },
];
