import { httpClient } from "@/services/api/client";

const endpoint = "/smartpower";

export default {
    // ********************
    // default REST methods
    // ********************
    // **************
    // custom methods
    // **************
    /**
     * Get SMET from MSP
     * @param smetFactoryNo
     * @returns a Promise containing a SMET
     */
    getSMET(smetFactoryNo) {
        return httpClient.get(`${endpoint}/smart-meters/${smetFactoryNo}`);
    },
    /**
     * Get SMAN from MSP
     * @param smanFactoryNo
     * @returns a Promise containing a SMAN
     */
    getSmanMinimal(smanFactoryNo) {
        return httpClient.get(`${endpoint}/smart-managers/${smanFactoryNo}/minimal`);
    },
    /**
     *
     * @param alias
     * @returns a Promise containing a single device type object
     */
    getDeviceTypeByAlias(alias) {
        return httpClient.get(`${endpoint}/device-type-alias/`, { params: { alias: alias } });
    },
    /**
     * Get all mediums
     * @returns a Promise containing an array of mediums
     */
    getMediums(params) {
        return httpClient.get(`${endpoint}/mediums/`, { params: params });
    },
    /**
     * Get a single medium by ID
     * @param id
     * @returns a Promise containing a single medium object. 404 if not found.
     */
    getMediumByID(id) {
        return httpClient.get(`${endpoint}/mediums/${id}`);
    },
    /**
     * Get the list of OBIS Codes available for a given smart meter
     * @param smetFactoryNo
     * @returns a Promise containing an array of OBIS Codes. [] if none found.
     */
    getObisCodes(smetFactoryNo) {
        return httpClient.get(`${endpoint}/smart-meters/${smetFactoryNo}/obis-codes`);
    },
};
