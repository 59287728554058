const identityAzureSettings = {
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_TENANT_ID}/v2.0/`, // required - repower: 6dc8f987-c886-4792-acef-76455cc290b9
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID, // required
    redirectUri: process.env.VUE_APP_WEB_ROOT_URL + "/callback", // required
    postLogoutRedirectUri: process.env.VUE_APP_WEB_ROOT_URL + "/logged-out",
    responseType: "code", // required - Changed from "id_token token" to "code"
    scope: `openid profile email ${process.env.VUE_APP_AZURE_API_SCOPE}`, // required - repower: api://fa0d5073-4d8a-452c-89fd-5f7a662a473a/EnergyspaceLoginTest
    silentRedirectUri: process.env.VUE_APP_WEB_ROOT_URL + "/silent-refresh",
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
    includeIdTokenInSilentRenew: false, // https://github.com/IdentityModel/oidc-client-js/issues/172
};

const executionDateAgreement = {
    // See also: Models.Enums.ExecutionDateAgreement.cs
    CUSTOMERAGREED: "Kunde",
    TECHNICIANDECIDED: "Techniker",
    AOPLANNED: "Auftragsoptimierung",
};

const constants = {
    env: {
        CLIENT_ID: process.env.VUE_APP_CLIENT_ID, // IS4 client id. EG: miVueTemplate
        WEB_APP_ROOT: process.env.VUE_APP_WEB_ROOT_URL, // the complete url of this application. EG: https://rbmidde02.corp.repower.com/Apps/miVueTemplate
        API_ROOT_URL: process.env.VUE_APP_API_ROOT_URL, // url of miPortalApi (used by ReMenu). EG: https://rbmidde02.corp.repower.com/Api
        API_PORTAL_URL: process.env.VUE_APP_API_ROOT_URL,
        API_SMARTMETER_URL: process.env.VUE_APP_API_ROOT_URL,
        NAME: process.env.VUE_APP_ENV_NAME, // Environment name: EG: development, staging, ...
        BASE_ROUTE: process.env.VUE_APP_BASE_ROUTE, // IIS relativa base route. EG: /Apps/miVueTemplate (a subpart of WEB_APP_ROOT)
        ASSETS_URL: process.env.VUE_APP_API_ROOT_URL + "/assets",
        BRIDGE_ROOT_URL: process.env.VUE_APP_BRIDGE_ROOT_URL,
    },
    identity: {
        ADMIN_ROLE: "miAdmin",
        REQUIRED_ROLES: ["gaAppRetasksUsers", "gaAppRetasksUsersGuestAccounts", "a6ff0e93-5c2b-415e-b6c5-37a0a6c5019d"],
        settings: identityAzureSettings,
    },
    api: {
        ORDERS: "/net/auftrags",
        USERS: "/app/users",
        GROUPS: "/app/groups",
    },
    db: {
        DB_NAME: "smartmeter",
        STORE_NAME: "smartmeter-store",
    },
    steps: {
        STEP_1_1_1: "1-1-1",
        STEP_2_1_1: "2-2-1",
        STEP_9_1_1: "9-1-1",
    },
    upload: {
        TIMEOUT_AFTER_AUTO_UPLOAD: 5000, // 5 seconds
        FULL_PROGRESS_BAR: 100, // Progressbar Value
        icon: {
            DEFAULT: "#default",
            UPLOADING: "#uploading",
            PAUSE: "#pause",
            DONE: "",
        },
        status: {
            IN_PROGRESS: true,
            COMPLETED: false,
            STOPPED: false,
        },
        exception: {
            PROGRESS_ERROR: "Upload läuft bereits.",
            CANCEL_ERROR: "Upload wurde abgebrochen",
        },
        MAX_LENGTH_UPLOAD_VIEW: 35,
    },
    connectivity: {
        MAX_AMMOUNT_OF_RESPONSE_TIMES: 4,
        IDEAL_RESPONSETIME: 150, // 150ms
        TOO_HIGH_RESPONSETIME: 1000, // 1000ms
        TIMEOUT_AFTER_FAILED_REQUEST: 5000, // 5 seconds
        TIMEOUT_AFTER_SUCCEEDED_REQUEST: 30000, // 30 seconds
    },
    mail: {
        PREVENT_SENDING_MAIL: true,
        SEND_MAIL: false,
    },
    image: {
        assetType: {
            IMAGE: "image",
        },
    },
    mainEsolvaContact: {
        name: "Beat Putzi",
        phoneNumber: "+41 56 458 60 00",
    },
    execList: {
        tabs: {
            PERSONAL_ITEM_ID: 0,
            COMPANY_ITEM_ID: 1,
            ADMIN_ITEM_ID: 2,
        },
    },
    WEB_APP_NAME: "rellout",
    TECHNICIAN_ROLE_ID: 3,
    LEITER_ROLE_ID: 2,
    ADMIN_ROLE_ID: 1,
    MATCH_ALL_ROLES: true,
    MATCH_SINGLE_ROLES: false,
    actionItem: {
        ACTION_ITEM_13: 13, // SMAN einbau
        ACTION_ITEM_14: 14, // SMAN Konfig
    },
    // suggested by gae (e-mail Tue 15.02.2022 12:23)
    auftragDetail: {
        // TODO: rinominare iactionDetail
        AUFTRAG_DETAIL_11: 11,
        ACTION_DETAIL_18: 18, // SMAN Einbau
        ACTION_DETAIL_19: 19, // SMAN Activation
        ACTION_DETAIL_20: 20, // SMAN Konfig
    },
    auftragDetailStatus: {
        ERROR: -1,
        NOT_STARTED: 0,
        IN_PROGRESS: 100,
        DONE_CLOSED: 200,
        DONE_REPEATABLE: 201,
        DONE_CONDITION_1: 202,
        DONE_CONDITION_2: 203,
        DONE_CONDITION_3: 204,
        DONE_CONDITION_4: 205,
        SKIPPED: 300,
        BLOCKED: 500,
    },
    externalServices: {
        ISE_ACTIVITY_CALLCENTER: 10498, // was: 10503, interface 8d
    },
    executionDateAgreement: executionDateAgreement,
    installationPlanStatusAmpel: {
        RED: "Red",
        YELLOW: "Yellow",
        GREEN: "Green",
    },
    readingType: {
        AUTO: "Ablesegerät autom.",
        MANUAL: "Manuell am Ablesegerät",
    },
    mirageTestData: {
        smanID: "RECHSM.00010.00210", // Usually the same in TEST
        smetID: "12011093", // Change this
    },
    userCompanyEnum: {
        // See also: UserCompanyEnum.cs
        Company: 0,
        User: 1,
    },
    communicationTechnology: {
        // See also: AuftragOptimisation.CommunicationTechnology.cs
        PLC: 1,
        LTE: 2,
        LAN: 3,
        LWL: 4,
    },
    /* See: https://styleguide.repower.com/web/colors/repower */
    calendarColor: {
        Company: "#ff463c",
        User: "#a2ad00",
    },
    voltageTypes: {
        NIEDERSPANNUNG: "NS",
        MITTELSPANNUNG: "MS",
    },
    daysArray: [
        { id: 1, name: "Montag", sorter: 0 },
        { id: 2, name: "Dienstag", sorter: 1 },
        { id: 3, name: "Mittwoch", sorter: 2 },
        { id: 4, name: "Donnerstag", sorter: 3 },
        { id: 5, name: "Freitag", sorter: 4 },
        { id: 6, name: "Samstag", sorter: 5 },
        { id: 0, name: "Sonntag", sorter: 6 },
    ],
    selectableExecutionDateAgreementTypes: [
        {
            text: executionDateAgreement.CUSTOMERAGREED,
            value: 0,
        },
        {
            text: executionDateAgreement.TECHNICIANDECIDED,
            value: 1,
        },
        {
            text: executionDateAgreement.AOPLANNED,
            value: 2,
            disabled: true,
        },
    ],
    actionTypesWandler: [
        { type: "confirmed", value: 0 },
        { type: "adjust", value: 1 },
        { type: "remove", value: 2 },
        { type: "add", value: 3 },
    ],
    DATE_FORMAT: "DD.MM.YYYY",
    DATETIME_FORMAT: "DD.MM.YYYY HH:mm:ss",
    DATE_FORMAT_ISO: "YYYY-MM-DD", // use this to send Date to Rest API
    DATETIME_FORMAT_ISO: "YYYY-MM-DDTHH:mm:ss.SSS", // use this to send DateTime to Rest API
    DATE_FORMAT_GRID: "dd.MM.yyyy",
    DATETIME_FORMAT_GRID: "dd.MM.yyyy HH:mm",
    DATETIME_FORMAT_FILE: "YYYYMMDD_HHmmss",
    KEY_VALUE_OPTION_ID_OF_CONSUMERCLASS_WITH_KEIN_SCHALTPROGRAMM: 9999999, // Fixed MSP ID, approved by Dirk
    // Messages
    NOT_FOUND_CODE: 404,
    NOT_FOUND_MESSAGE: "Das Objekt wurde nicht in der Datenbank gefunden.",
    // Bus Address Algorithm
    OMNIPOWER_BUS_ADDRESS_ALGORITHM: "WerkNrXX-50",
};

export const getRouteBase = function() {
    return process.env.VUE_APP_BASE_ROUTE;
};

export default constants;
