<template>
    <div v-if="asq" class="d-flex">
        <div
            v-if="displayOrchestratorCreate"
            class="d-flex align-items-center re-phase"
            v-b-tooltip.hover
            :title="displayPhaseTooltips ? 'Auftrag Creation (Orchestrator)' : ''"
        >
            <div class="d-flex flex-column">
                <OrchestratorEtlStatus name="ETL451a" :asq="asq" />
                <OrchestratorEtlStatus name="ETL480" :asq="asq" class="mt-1" />
            </div>
            <OrchestratorEtlStatus name="ETL453" :asq="asq" class="ml-1" />
        </div>
        <div
            v-if="displayAuftragAusfuehrung"
            class="d-flex re-phase ml-1"
            v-b-tooltip.hover
            :title="displayPhaseTooltips ? 'Auftrag Ausführung (AA)' : ''"
        >
            <div class="d-flex align-self-center">AA</div>
        </div>
        <div
            v-if="displayOrchestratorComplete"
            class="d-flex align-items-center re-phase ml-1"
            v-b-tooltip.hover
            :title="displayPhaseTooltips ? 'Auftrag Completion (Orchestrator)' : ''"
        >
            <div class="d-flex align-items-center">
                <div v-b-tooltip.hover title="REtasks to MSP (SMARTPOWER)" class="p-1 bg-light re-orch-etl-temp">
                    <span class="text-nowrap">MSP</span>
                </div>
                <div class="flex-column ml-1">
                    <OrchestratorEtlStatus name="ETL451b" :asq="asq" />
                    <div class="d-flex mt-1">
                        <OrchestratorEtlStatus name="ETL454" :asq="asq" :displayTooltipTodo="true" />
                        <OrchestratorEtlStatus name="ETL481" :asq="asq" class="ml-1" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrchestratorEtlStatus from "@/components/order/OrchestratorEtlStatus";

export default {
    name: "OrchestratorStatus",
    components: { OrchestratorEtlStatus },
    props: {
        asq: {
            type: Object,
        },
        displayAuftragAusfuehrung: {
            type: Boolean,
            default: false,
        },
        displayOrchestratorComplete: {
            type: Boolean,
            default: false,
        },
        displayOrchestratorCreate: {
            type: Boolean,
            default: false,
        },
        displayPhaseTooltips: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.re-phase {
    border: 1px solid darkgrey;
    font-size: 0.8rem;
    padding: 6px;
}
.re-orch-etl-temp {
    border: 1px solid darkgrey;
    border-radius: 4px;
}
</style>
