import { httpClient } from "@/services/api/client";

const endpoint = "/net/auftrags";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient
            .get(endpoint, {
                params: params,
            })
            .then((resp) => {
                return resp.data;
            });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload).then((resp) => {
            return resp.data;
        });
    },
    // **************
    // custom methods
    // **************
    getByTechnicianId(id) {
        return this.get({ technicianid: id }).then((resp) => {
            return resp;
        });
    },
    postPhase(auftragSyncQueueID, phase) {
        return httpClient.post(`${endpoint}/phase/${phase}/`, null, {
            params: { auftragSyncQueueID: auftragSyncQueueID },
        });
    },
    bulkPatch(payload) {
        return httpClient.patch(`${endpoint}/bulk`, payload).then((resp) => {
            return resp.data;
        });
    },
    bulkDelete(data) {
        return httpClient
            .delete(`${endpoint}/bulk`, {
                data: data,
            })
            .then((resp) => {
                return resp.data;
            });
    },
};
