<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !$attrs.devicePool">
        <template v-if="actAuftragDetail && $attrs.devicePool">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div class="mb-2">
                Zähler mit Telemetering im Gerätestandort [{{ fernablesungMeters.length }}]
                <ol v-if="fernablesungMeters.length" class="my-0">
                    <li v-for="meter in fernablesungMeters" :key="meter.deviceID">
                        {{ meter.factoryNo }}
                        <font-awesome-icon :icon="['fas', 'info-circle']" :id="`md-${meter.deviceID}`" />
                        <b-popover :target="`md-${meter.deviceID}`" title="Details" triggers="focus">
                            <b-table-simple class="m-0 re-custom-table" hover small borderless>
                                <b-tbody>
                                    <b-tr>
                                        <b-td>Fabriknr.:</b-td>
                                        <b-td>{{ meter.factoryNo }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Typ:</b-td>
                                        <b-td>{{ meter.deviceType }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Telemeter Typ:</b-td>
                                        <b-td>{{ meter.telemeteringType }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Beschreibung:</b-td>
                                        <b-td>{{ meter.description }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-popover>
                    </li>
                </ol>
            </div>
            <div>
                Zähler mit Breaker [{{ breakerMeters.length }}]
                <ol v-if="breakerMeters.length">
                    <li v-for="meter in breakerMeters" :key="meter.deviceID">
                        {{ meter.factoryNo }}
                        <font-awesome-icon :icon="['fas', 'info-circle']" :id="`aa-${meter.deviceID}`" />
                        <b-popover :target="`aa-${meter.deviceID}`" title="Details" triggers="focus">
                            <b-table-simple class="m-0 re-custom-table" hover small borderless>
                                <b-tbody>
                                    <b-tr>
                                        <b-td>Fabriknr.:</b-td>
                                        <b-td>{{ meter.factoryNo }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Typ:</b-td>
                                        <b-td>{{ meter.deviceType }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Breaker:</b-td>
                                        <b-td>{{ meter.isBreaker ? "Ja" : "Nein" }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Beschreibung:</b-td>
                                        <b-td>{{ meter.description }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-popover>
                    </li>
                </ol>
            </div>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
            <b-button
                v-if="fernablesungMeters.length > 0"
                class="my-3 d-flex ml-auto"
                @click="onClickRemoteReading"
                :disabled="compIsLoadingRemote || fernablesungMeters.length === 0"
                variant="primary"
            >
                Fernablesung starten <b-spinner v-if="compIsLoadingRemote" small />
            </b-button>
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";
import zfaService from "@/services/zfaService";
import constants from "@/constants/constants";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

export default {
    name: "Step_2_1_1",
    components: {
        ExecSectionErledigt,
        ExecButtonNext,
        ExecButtonPrevious,
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            devicePool: null,
            isLoadingRemote: false,
            fernablesungMeters: [],
            breakerMeters: [],
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            zfaResponses: [],
            isFernablesungDone: false,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compIsLoadingRemote() {
            return this.isLoadingRemote;
        },
        // ToDo(clarify): this is only used in mounted() -> using it like this is useless and considered a bad practice
        compIsStepToSkip() {
            return this.fernablesungMeters.length === 0 && this.breakerMeters.length === 0;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];
            if (this.fernablesungMeters.length === 0) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Kein Zähler mit Fernablesung im Gerätestandort.",
                });
            } else {
                if (this.isFernablesungDone) {
                    preconditionsLocalDetails.push({ status: "FULFILLED", message: "Fernablesung durchgeführt." });
                } else {
                    preconditionsLocalDetails.push({ status: "OPEN", message: "Fernablesung nicht gestartet." });
                }
            }
            return preconditionsLocalDetails;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        // ToDo: find a better name than "res"
        const res = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId
        );
        await this.setActAuftragDetail(res);
        this.loadValue();
        // GET meters
        await devicesAPI
            .get({
                devicePoolID: this.$route.params.devicePoolId,
                type: "Elektrizitätszähler",
                includeDeviceInfos: true,
            })
            .then((r) => {
                this.breakerMeters = r.filter((d) => {
                    return d.isBreaker === true;
                });

                this.fernablesungMeters = r.filter((d) => {
                    return d.telemeteringType > 0;
                });
            });
        // for each meter that is not capable of telemetering set the status of step 7-1-2 (Fernablesung) to 300 skipped
        const auftragDetail712 = await auftragDetailsAPI.get({
            auftragID: this.$route.params.orderId,
            actionGroupSequence: 7,
            actionItemSequence: 1,
            actionDetailSequence: 2,
            devicePoolID: this.$route.params.devicePoolId,
        });
        auftragDetail712.forEach((auftragDetail) => {
            if (!this.fernablesungMeters.some((obj) => obj.deviceID === auftragDetail.deviceID)) {
                if (auftragDetail.status !== constants.auftragDetailStatus.SKIPPED) {
                    auftragDetailsAPI.patch(auftragDetail.auftragDetailID, [
                        { op: "replace", path: "/status", value: constants.auftragDetailStatus.SKIPPED },
                    ]); // 300 - step 7-1-2 skipped
                }
            }
        });
        // ToDo(clarify): it is a bad practice to use a computed prop in mounted()
        if (this.compIsStepToSkip) {
            // ToDo(clarify): there should be a toast message that the step is skipped (so the user is informed)
            console.log(
                `Skipping Step 2-1-1 - Status: ${this.actAuftragDetail.status} - ID ${this.actAuftragDetail.auftragDetailID}`
            );
            // ToDo(clarify): why so hacky?
            // SKIP if no meters with telemetering/breaker meters found - save status if not done yet
            if (this.actAuftragDetail.status !== 202) {
                await this.onClickErledigt();
            } else {
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            }
        }
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        loadValue() {
            // load form
            if (this.actAuftragDetail && this.actAuftragDetail.value) {
                this.zfaResponses = JSON.parse(this.actAuftragDetail.value);
            }
        },
        async onClickErledigt() {
            const status =
                this.fernablesungMeters.length === 0
                    ? constants.auftragDetailStatus.SKIPPED
                    : constants.auftragDetailStatus.DONE_CONDITION_1;
            await this.save(status);
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        async onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        async onClickRemoteReading() {
            // reset status before each call
            this.zfaResponses = [];
            this.isLoadingRemote = true;
            const successArray = [];
            const errorArray = [];
            try {
                for (const meter of this.fernablesungMeters) {
                    let result = await zfaService.executeZfaJobReadSaveToStep712(
                        this.$route.params.orderId,
                        this.$route.params.devicePoolId,
                        meter
                    );
                    if (result.code === 0) {
                        successArray.push(`${meter.factoryNo}`);
                    } else {
                        let errorTypeText;
                        const resultCode = result.code;
                        // result.code: 0 if success, less than 0 for technical errors, greater than 0 for business error
                        switch (resultCode) {
                            case resultCode < 0:
                                errorTypeText = `technischer`;
                                break;
                            case resultCode > 0:
                                errorTypeText = `Business`;
                                break;
                        }
                        errorArray.push(
                            `${meter.factoryNo}: Während der Fernablesung ist ein ${errorTypeText} Fehler aufgetreten (code: ${resultCode})`
                        );
                    }
                    this.zfaResponses.push(result);
                }

                if (successArray.length > 0) {
                    this.$bvToast.toast(`OK: ${successArray.join(", ")}`, {
                        title: "Success",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                    });
                }
                if (errorArray.length > 0) {
                    this.$bvToast.toast(`${errorArray.join(", ")}`, {
                        title: "Fehler",
                        variant: "warning",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                    });
                }
            } finally {
                this.isLoadingRemote = false;
                // ToDo(clarify): why don't care?
                this.isFernablesungDone = true; // Don't care about the OK/KO from ZFA
            }
        },
        async save(status) {
            if (this.actAuftragDetail.status === 0) {
                await auftragDetailsAPI.patchValueAndStatusOnDiff(
                    this.actAuftragDetail.auftragDetailID,
                    this.actAuftragDetail.value,
                    this.zfaResponses,
                    status
                );
            }
        },
    },
};
</script>

<style scoped>
.re-custom-table {
    td {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    tr td:nth-child(1) {
        font-weight: bold;
        width: 110px;
    }
}
</style>
