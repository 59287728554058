<template>
    <div>
        <h3 class="mb-3 mt-2 fw-bold">Einstellungen bearbeiten</h3>
        <div class="d-flex flex-column gap-3" style="gap: 15px;">
            <div v-for="(setting, index) in applicationSetting" :key="index" class="d-flex align-items-center">
                <div class="fw-bold" style="flex: 1; margin-right: 20px;">
                    {{ setting.name }}
                </div>
                <div class="fw-bold" style="flex: 1;">
                    <template v-if="isBoolean(setting.value)">
                        <DxCheckBox v-model="setting.value" />
                    </template>
                    <template v-else>
                        <DxTextBox v-model="setting.value">
                            <DxValidator>
                                <DxRequiredRule message="Dieses Feld muss ausgefüllt sein." />
                            </DxValidator>
                        </DxTextBox>
                    </template>
                </div>
            </div>
            <div class="mt-4 text-end">
                <DxButton
                    text="Speichern"
                    type="danger"
                    class="ml-2 float-right"
                    @click="onClickSave"
                    :disabled="isSaveDisabled"
                    :style="{ opacity: isSaveDisabled ? 0.4 : 1 }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import applicationSettingAPI from "@/services/api/applicationSetting.api";
import { DxButton } from "devextreme-vue";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import constants from "@/constants/constants";
import { mapGetters } from "vuex";

export default {
    name: "Administration",
    components: {
        DxCheckBox,
        DxButton,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
    },

    data() {
        return {
            applicationSetting: [],
            constants: constants,
        };
    },

    computed: {
        ...mapGetters(["currentUser"]),

        isSaveDisabled() {
            const hasChanges = this.applicationSetting.some((setting) => setting.originalValue !== setting.value);
            const hasEmptyFields = this.applicationSetting.some(
                (setting) => !setting.value && !this.isBoolean(setting.value)
            );
            return !hasChanges || hasEmptyFields;
        },
    },

    watch: {
        currentUser: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.userRoles && !this.userRolesFetched) {
                    this.userRolesFetched = true;
                    if (this.doesUserHaveRole(this.constants.ADMIN_ROLE_ID)) {
                        this.fetchSettings();
                    } else {
                        this.$router.replace({ name: "unauthorized", query: { reason: "role" } });
                    }
                }
            },
        },
    },
    methods: {
        isBoolean(value) {
            return value === true || value === false;
        },

        async onClickSave() {
            try {
                const updateSettingPromises = this.applicationSetting
                    .filter((setting) => setting.originalValue !== setting.value)
                    .map((setting) => {
                        setting.originalValue = setting.value;
                        return applicationSettingAPI.put(setting.name, {
                            name: setting.name,
                            value: setting.value.toString(),
                        });
                    });

                const responses = await Promise.all(updateSettingPromises);

                const allSuccess = responses.every((response) => response.status === 204);

                if (allSuccess) {
                    this.$bvToast.toast("Die Einstellungen wurden erfolgreich gespeichert.", {
                        title: "SUCCESS",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: false,
                        appendToast: true,
                    });
                }
            } catch (error) {
                this.$bvToast.toast("Die Einstellungen konnten nicht gespeichert werden.", {
                    title: "ERROR",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: false,
                    appendToast: true,
                });
            }
        },

        async fetchSettings() {
            try {
                const response = await applicationSettingAPI.getAll();
                this.applicationSetting = response.data.map((setting) => ({
                    ...setting,
                    originalValue: this.parseValue(setting.value),
                    value: this.parseValue(setting.value),
                }));
            } catch (error) {
                console.error("Fehler beim Laden der Daten");
            }
        },

        parseValue(value) {
            if (value === "true") return true;
            if (value === "false") return false;
            return value;
        },

        doesUserHaveRole(roleID) {
            if (!this.currentUser) return false;
            return this.currentUser.userRoles.some((userRole) => userRole.roleID === roleID);
        },
    },
};
</script>
