<template>
    <div>
        <b-link v-if="jobId" :href="compUrlHangfireJob" target="_blank">
            {{ compLinkLabel }} <sup><font-awesome-icon :icon="['fas', 'up-right-from-square']"/></sup>
        </b-link>
        <template v-else>
            -
        </template>
    </div>
</template>

<script>
export default {
    name: "HangfireLinkJob",
    props: {
        jobId: {
            type: [Number, String],
        },
        linkLabel: {
            type: String,
            default: "link",
        },
    },
    computed: {
        compUrlHangfireJob() {
            // hangfire job link example: https://retasks.repower.com/jobs/jobs/details/13311
            return `${process.env.VUE_APP_WEB_ROOT_URL}/jobs/jobs/details/${this.jobId.toString()}`;
        },
        compLinkLabel() {
            return this.jobId ? this.jobId.toString() : "link";
        },
    },
};
</script>
