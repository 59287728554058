var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',{attrs:{"headerText":"Gerätestandort","isLoading":!_vm.devicePool || !_vm.order}},[(_vm.order)?_c('div',{staticClass:"d-flex"},[_c('div',[_c('router-link',{attrs:{"to":{ name: 'execution-single', params: { orderId: _vm.order.auftragID } }}},[_vm._v(" "+_vm._s(_vm.order.hak.tp)+" ")])],1),_c('div',{staticClass:"ml-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.order.hak.address)+" ")])]):_vm._e(),(_vm.order && _vm.devicePool)?_c('div',{staticClass:"d-flex"},[_c('div',[_c('router-link',{attrs:{"to":{
                    name: 'execution-geraetestandort',
                    params: {
                        orderId: _vm.order.auftragID,
                        devicePoolId: _vm.devicePool.devicePoolID,
                    },
                }}},[_vm._v(" "+_vm._s(_vm.devicePool.position)+" "),(_vm.devicePool.positionDetail)?_c('span',[_vm._v(" - "+_vm._s(_vm.devicePool.positionDetail))]):_vm._e()])],1),(_vm.order.hak.address !== _vm.devicePool.address)?_c('div',{staticClass:"ml-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.devicePool.address)+" ")]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }