import auftragDetailsAPI from "@/services/api/auftragDetails.api";

export const navigation = {
    methods: {
        navigateTo(payload) {
            const deviceId = payload.deviceId;
            const devicePoolId = payload.devicePoolId;
            const orderId = payload.orderId;
            const step = payload.step;
            const auftragItemId = payload.auftragItemId;

            let stepBase = "step";
            switch (step) {
                case "B":
                case "1":
                    this.$router.push({
                        name: "execution-single",
                        params: { orderId: orderId },
                    });
                    break;
                case "2":
                case "D":
                    this.$router.push({
                        name: "execution-geraetestandort",
                        params: { orderId, devicePoolId },
                    });
                    break;
                case "5":
                    this.$router.push({
                        name: "execution-flexibilities",
                        params: { orderId, devicePoolId },
                    });
                    break;
                case "6":
                    this.$router.push({
                        name: "execution-rse-sman",
                        params: { orderId, devicePoolId },
                    });
                    break;
                case "7":
                    this.$router.push({
                        name: "execution-meters",
                        params: { orderId, devicePoolId, auftragItemId },
                    });
                    break;
                case "7.x":
                    this.$router.push({
                        name: "execution-meter-single",
                        params: { orderId, devicePoolId, deviceId },
                    });
                    break;
                case "8":
                    this.$router.push({
                        name: "execution-other-devices",
                        params: { orderId, devicePoolId },
                    });
                    break;
                default:
                    switch (true) {
                        case step.startsWith("6-"):
                            stepBase = "step-6";
                            break;
                        case step.startsWith("7-"):
                            stepBase = "step-7";
                            break;
                        case step.startsWith("8-"):
                            stepBase = "step-8";
                            break;
                        case step.startsWith("9-"):
                            stepBase = "step-9";
                            break;
                        default:
                            stepBase = "step";
                    }
                    auftragDetailsAPI
                        .getByOrderIdAndStep(orderId, step, devicePoolId, deviceId, auftragItemId)
                        .then((res) => {
                            this.setActAuftragDetail(res);
                        })
                        .then(() => {
                            if (devicePoolId) {
                                if (auftragItemId) {
                                    this.$router.push({
                                        path: `/execution/${orderId}/${devicePoolId}/${auftragItemId}/${stepBase}/${step}`,
                                    });
                                } else if (deviceId) {
                                    this.$router.push({
                                        path: `/execution/${orderId}/${devicePoolId}/${deviceId}/${stepBase}/${step}`,
                                    });
                                } else {
                                    this.$router.push({
                                        path: `/execution/${orderId}/${devicePoolId}/${stepBase}/${step}`,
                                    });
                                }
                            } else {
                                this.$router.push({ path: `/execution/${orderId}/${stepBase}/${step}` });
                            }
                        });
            }
        },
    },
};
